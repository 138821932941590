import React from 'react'
import { RichText } from 'prismic-reactjs'
import classNames from 'classnames/bind'

import { linkResolver } from 'prismic/linkResolver'
import { htmlSerializerLight } from 'prismic/htmlSerializer'
import useTheme from 'lib/useTheme'
import SEO from 'components/core/SEO'
import Spacer from 'components/core/Spacer'
import Container from 'components/core/Container'
import Text3D from 'assets/svg/404.react.svg'
import { FullscreenGlobe } from 'components/ui/Globe'
import Footer from 'components/ui/Footer'
import { ParallaxLayer, ParallaxLayers } from 'components/ui/ParallaxLayers'
import { PrimaryButton } from 'components/ui/Buttons'

import * as s from './NotFoundPage.module.scss'
const cn = classNames.bind(s)
import { NotFoundPageTypes } from './NotFoundPageTypes'

const NotFoundPage = ({ data }: NotFoundPageTypes) => {
  useTheme('dark', 'white')

  const document = data?.prismicNotFoundPage
  const footer = data?.prismicSiteSettings

  return (
    <div className={cn('container')}>
      <SEO
        title={document.data.page_meta_title}
        description={document.data.page_meta_description}
        thumbnail={document.data.page_meta_thumbnail}
      />

      <div className={cn('inner')}>
        <FullscreenGlobe />
        <Container className={cn('content')}>
          <h1 className={cn('srOnly')}>{document.data.title}</h1>
          <ParallaxLayers damping={20} stiffness={100} type='window'>
            <ParallaxLayer intensity={1}>
              <Text3D className={cn('text3d')} />
            </ParallaxLayer>
          </ParallaxLayers>
          <RichText render={document.data.description.raw} htmlSerializer={htmlSerializerLight()} />
          <Spacer initial='3vh' medium='2vh' />
          <PrimaryButton inverted to={linkResolver(document.data.cta_link)}>
            {document.data.cta_link_label}
          </PrimaryButton>
        </Container>
      </div>
      <Footer data={footer.data} />
    </div>
  )
}

export default NotFoundPage
