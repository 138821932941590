import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  useMergePrismicPreviewData,
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { useLocation } from '@reach/router'

import NotFoundView from 'views/NotFoundPage'
import { linkResolver } from 'prismic/linkResolver'
import FactTemplate from 'templates/fact'
import getLocalizedData from 'lib/getLocalizedData'

import { prismicRepo } from '../../prismic-configuration'

const notFoundQuery = graphql`
  query notFoundQuery {
    allPrismicNotFoundPage {
      edges {
        node {
          id
          lang
          type
          data {
            page_meta_title
            page_meta_description
            page_meta_thumbnail {
              url
              dimensions {
                height
                width
              }
              alt
            }
            cta_link_label
            cta_link {
              uid
              lang
              type
            }
            page_meta_description
            page_meta_title
            title
            description {
              raw
            }
            page_meta_thumbnail {
              gatsbyImageData
            }
          }
        }
      }
    }
    allPrismicSiteSettings {
      edges {
        node {
          _previewable
          lang
          type
          data {
            footer_copyright
            footer_bottom_links {
              footer_bottom_link {
                uid
                type
                lang
              }
              footer_bottom_link_text
            }
            footer_description {
              raw
            }
            footer_logos {
              footer_logo_image {
                gatsbyImageData
                alt
              }
            }
            footer_social_links {
              footer_social_link_icon
              footer_social_link_text
              footer_social_link {
                url
              }
            }
          }
        }
      }
    }
  }
`

const NotFound = () => {
  const staticData = useStaticQuery(notFoundQuery)
  const { data } = useMergePrismicPreviewData(staticData)
  const { pathname } = useLocation()

  const prismicNotFoundPage = getLocalizedData(pathname, data.allPrismicNotFoundPage)
  const prismicSiteSettings = getLocalizedData(pathname, data.allPrismicSiteSettings)

  return <NotFoundView data={{ prismicSiteSettings, prismicNotFoundPage }} />
}

const NotFoundPage = () => {
  return <NotFound />
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
    componentResolver: componentResolverFromMap({
      fact: FactTemplate,
    }),
  },
])
